import {toQueryString} from "../utils";
import {redisGetHandler} from "../utils/redis";

export default (axios, config) => ({
    async searchProducts(q, cancelToken, isBaltex = false) {
        const {data} = await axios.get(
            `${config.API_URL}/catalog_v2/searchProducts?q=${q}&isBaltex=${isBaltex}`,
            {cancelToken}
        );
        return data;
    },
    async getManagerData(ids) {
        const url = `${config.API_URL}/catalog_v2/getManagerData`
        const {data} = await axios.get(url + '?' + toQueryString({ids}));
        return data;
    },
    async getManagerComments(ids) {
        const url = `${config.API_URL}/catalog_v2/getManagerComments`
        const {data} = await axios.get(url + '?' + toQueryString({ids}));
        return data;
    },
    async getFilterNames(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/catalog_v2/getFilterNames`, reset);
    },
    async getClientComments(Redis, reset = false) {
        return await redisGetHandler(Redis, axios, config.API_URL, `/catalog_v2/getClientComments`, reset);
    },
});